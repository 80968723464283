import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import GlobalStyles from '@mui/material/GlobalStyles'
import { ThemeProvider } from '@mui/material/styles'
import React, { ReactNode } from 'react'
import theme from 'theme'
import { TssCacheProvider } from 'tss-react'

import spaces from 'components/PageLayout/spaces'

const muiCache = createCache({
  key: 'mui',
  prepend: true,
})

muiCache.compat = true

const tssCache = createCache({
  key: 'tss',
})

tssCache.compat = true

type Props = {
  children: ReactNode
}

function StyleProvider({ children }: Props) {
  return (
    <CacheProvider value={muiCache}>
      <TssCacheProvider value={tssCache}>
        <ThemeProvider theme={theme}>
          <GlobalStyles styles={spaces} />
          {children}
        </ThemeProvider>
      </TssCacheProvider>
    </CacheProvider>
  )
}

export default StyleProvider
